/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const render = (args, callback, instance) => {
    let LocalizationWrapperElement = callback(...args);
    delete LocalizationWrapperElement.props.style.overflow;
    return LocalizationWrapperElement;

};
export default {
    'RuntimeI18n/Component/I18n/Component/I18nComponent': {
        'member-function': {
            render
        }
    }
};
